<template>
    <section class="content">
        <table class="table table-hover table-striped table-no-border" ref="tablesuswa">
            <thead>
                <tr>
                    <th>Tryout</th>
                    <th>TANGGAL UJIAN</th>
                    <th>Skor Verbal</th>
                    <th>Skor Kuantitatif</th>
                    <th>Skor Penalaran</th>
                    <th>Skor Akhir</th>
                    <th>Opsi</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import moment from "moment";

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            filter: {},
            siswa: {},
            method: '',
            roles: '',
            id: '',
            branch: '',
            userid: '',
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.target.matches(".pembahasan")) {
                this.$router.push({ path: "/ujian-finish/" + e.target.dataset.id });
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesuswa, {
            "title": "Rapor Evaluasi",
            "roles": ["read"],
            "ajax": "/siswa/ujian/hasil_ujian",
            "columns": [
                { "data": "ujian_title" },
                { "data": "tanggal",
                render: function (data, type, row, meta) {
                    return moment(data).format("DD/MM/YYYY hh:mm");
                } },
                { "data": "verbal" },
                { "data": "kuantitatif" },
                { "data": "penalaran" },
                { "data": "final_score" },
                { "data": "final_score" },
            ],
            filter: false,
            scrollX: true,
            rowCallback: function(row, data) {
            },
            paramData: function(d) {
                d.paket = 3;
                var filter = JSON.parse(JSON.stringify(self.$route.params.jenis));
                for (const key in filter) {
                    d[key] = filter[key] === false ? 0 : filter[key];
                }
            },
            rowCallback: function (row, data) {
                $("td:eq(6)", row).html(
                    '<button class="btn btn-outline-primary pembahasan" data-id="'+data.ujian_code+'">Pembahasan</button>'
                );
                $("td:eq(2)", row).html(data.verbal ? data.verbal : 0);
                $("td:eq(3)", row).html(data.kuantitatif ? data.kuantitatif : 0);
                $("td:eq(4)", row).html(data.penalaran ? data.penalaran : 0);
            },
        });
    }
}
</script>
<style type="text/css" scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .table thead th {
        border-bottom:  none;
    }
    .table td, .table th {
        border-top:  none;   
    }
    .table td {
        vertical-align: middle;
    }
</style>